.scrollable-select {
  .css-1s2u09g-control {
    border-width: 0 0 1px 0;
    border-radius: 0;
    border-color: #000;
    font-size: 1.375rem;
  }
  .css-1s2u09g-control:hover {
    border-color: #000;
  }

  .css-tlfecz-indicatorContainer {
    color: #000;
  }
  .css-tlfecz-indicatorContainer:hover {
    color: #000;
  }
  .css-1gtu0rj-indicatorContainer {
    color: #000;
  }
  .css-1gtu0rj-indicatorContainer:hover {
    color: #000;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-1pahdxg-control {
    border-width: 0 0 1px 0;
    border-radius: 0;
    border-color: #000;
    box-shadow: none;
  }
  .css-1pahdxg-control:hover {
    border-color: #000;
  }

  .css-319lph-ValueContainer {
    padding: 0;
  }
  .css-1d8n9bt {
    padding: 0;
  }

  .css-qc6sy-singleValue {
    font-size: 1.375rem;
  }

  .css-4ljt47-MenuList {
    ::-webkit-scrollbar {
      width: '1.2em';
      height: '1.2em';
    }
    ::-webkit-scrollbar-track {
      border-radius: '30px';
      background-color: #9ae8fc;
      border: '5px transparent solid';
      background-clip: 'padding-box';
    }
    ::-webkit-scrollbar-thumb {
      border-radius: '30px';
      background-color: #00ccff;
      border: '5px transparent solid';
      background-clip: 'padding-box';
    }
  }
}
