$calendar-font: 0.875rem 'Roboto', sans-serif;
.fc {
  .fc-header-toolbar {
    .fc-button {
      filter: drop-shadow(rgba(0, 0, 0, 0.16) 0.125rem 0.25rem 0.375rem);
      transition: background 0.8s;
      box-sizing: border-box;
      box-shadow: rgba(0, 0, 0, 0.2) 0rem 0.1875rem 0.0625rem -0.125rem,
        rgba(109, 96, 96, 0.14) 0rem 0.125rem 0.125rem 0rem,
        rgba(0, 0, 0, 0.12) 0rem 0.0625rem 0.3125rem 0rem;
      border: none;
      border-radius: 0.3125rem;
      background-position: center;
      background-color: #6f81ff;
      padding: 0.375rem 1rem;
      min-width: 4rem;
      color: #ffffff;
      font-weight: 500;
      line-height: 1.53125rem;
      letter-spacing: 0.02499875rem;
      text-transform: uppercase;
      &:disabled {
        box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
        background-color: rgb(111, 129, 255);
      }
      &:hover:enabled {
        background: rgb(77, 90, 178);
      }
    }
  }
  .fc-event-title-container div,
  .fc-event-main div {
    height: 26px;
    align-items: center;
    align-content: space-between;
    display: flex;
    padding: 1px;
    justify-content: space-between;
  }

  .fc-daygrid-more-link {
    background: #6e6e84;
    width: 100%;
    display: block;
    padding: 1px 0 1px 2px;
    border-radius: 0.125rem;
    opacity: 1;
    z-index: 3;
    color: #ffffff;
  }

  .fc-daygrid-day-events {
    align-self: end;
  }
  .half-day {
    width: 50%;
  }

  .fc-popover {
    border: none;
    background: none;
    box-shadow: none;
    height: 26px;
    .fc-popover-header {
      display: none;
    }

    .fc-popover-body {
      min-width: fit-content;
      padding: 10px;
      margin-left: 0.15rem;
      margin-top: 8em;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      border-radius: 0.125rem;
      position: relative;
      border-color: none;
      background-color: #6e6e84;

      .fc-h-event {
        display: contents;

        .fc-event-main {
          max-width: fit-content;
          .fc-event-main-frame {
            max-width: fit-content;
          }
        }
      }
      .fc-daygrid-event-harness {
        max-width: min-content;
        display: grid;
        justify-content: end;
        align-items: start;
        justify-items: stretch;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: flex-start;
      }
    }
  }
  .fc-daygrid-day.fc-day-today {
    background-color: #f4f4fa;
  }
  .fc-toolbar h2 {
    color: #151523;
  }
  .fc-col-header-cell-cushion {
    color: #151523;
  }
  .fc-daygrid-day-number {
    color: #151523;
  }
  .fc-daygrid-event {
    font-size: var(--chakra-fontSizes-size3);
    .chakra-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (min-width: 30rem) {
  .fc {
    .fc-popover {
      .fc-popover-body {
        margin-top: 3.5em;
      }
    }
    .fc-toolbar {
      flex-direction: column;
    }
  }
}

@media screen and (min-width: 48rem) {
  .fc {
    .fc-popover {
      .fc-popover-body {
        margin-top: 5em;
      }
    }
    .fc-toolbar {
      flex-direction: row;
    }
  }
}

@media screen and (min-width: 62rem) {
  .fc {
    .fc-popover {
      .fc-popover-body {
        margin-top: 6.5em;
      }
    }
  }
}

@media screen and (min-width: 80rem) {
  .fc {
    .fc-popover {
      .fc-popover-body {
        margin-top: 8em;
      }
    }
  }
}
