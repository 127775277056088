.css-b62m3t-container {
  width: 100%;
}
.multi {
  .css-1s2u09g-control {
    border-color: #000;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
  }
  .css-1rhbuit-multiValue {
    background-color: #fff;
  }
  .css-g1d714-ValueContainer {
    max-height: 150px;
    overflow-y: auto;
    flex-wrap: none;
  }

  .css-9gakcf-option {
    background-color: #e2e8f0;
    color: #000;
  }
  .css-1pahdxg-control {
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    box-shadow: none;
  }
  .css-tj5bde-Svg {
    color: #000;
    width: 70%;
    height: 70%;
  }
  .checkbox {
    accent-color: #6f81ff;
  }
  .css-1okebmr-indicatorSeparator {
    background-color: #000;
  }
  .css-4ljt47-MenuList {
    ::-webkit-scrollbar {
      width: '1.2em';
      height: '1.2em';
    }
    ::-webkit-scrollbar-track {
      border-radius: '30px';
      background-color: #9ae8fc;
      border: '5px transparent solid';
      background-clip: 'padding-box';
    }
    ::-webkit-scrollbar-thumb {
      border-radius: '30px';
      background-color: #00ccff;
      border: '5px transparent solid';
      background-clip: 'padding-box';
    }
  }
}
